export function isIterable(obj: any) {
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}
//
export function map(key: string, val: Record<string, any>): any {
  if (isIterable(val)) {
    {
      return Object.keys(val).filter((v) => {
        if (isIterable(val[v])) {
          return map(key, val[v]);
        } else {
          return v === key ? val[v] : false;
        }
      });
    }
  }
}

export function getHashMap(arr: [{ code: any; number: any }]) {
  return isIterable(arr) ? Object.assign(...arr) : arr;
}

export function hasProperty(arr: any, property: string) {
  return arr.every((value: Record<any, string>) => value[property]);
}
