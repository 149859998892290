
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import { mixins } from 'vue-class-component';
import Upload from '@/components/Upload.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Loading } from '@/modules/auth/store/auth.service';

import { Auth } from '@/store/modules/auth.module';

import * as _ from 'lodash';
import attachment from '@/components/attachment/attachment.vue';
import { isEmpty } from 'lodash';
import AccommodationSchema from '@/modules/accommodation/schema/accommodation.schema';
import { getHashMap } from '@/app/utils/array';
import { AccommodationDto, AccommodationDtoDocuments, IIndemnity } from '../../../app/entities/tenant/accommodation.dto';
import { appModule } from '../../../store/modules/app.module';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({
  components: {
    Upload,
    attachment,
  },
})
export default class AccommodationDetails extends mixins(AccommodationSchema) {
  @Ref('form') readonly form: any;

  @Ref('detailsForm') readonly detailsForm: FormulateForm;

  @Ref('healthDisclaimerForm') readonly healthDisclaimerForm: FormulateForm;

  @Ref('documentForm') documentForm: any;

  @PropSync('active') activeSync!: boolean;

  @PropSync('currentStep') syncedCurrentStep!: number;

  @PropSync('accommodation') syncedAccommodation!: any;

  @PropSync('logo') syncedLogo!: any;

  @PropSync('isEditingSync') syncedIsEditing!: boolean;

  @Prop({ default: () => null }) accommodationEdit: any;

  $refs!: any;

  baseUrl = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/upload/`;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  singleDocument: any = {
    attachEmail: false,
  };

  isLoading = false;

  _ = _;

  isOn = false;

  activeDoc = 0;

  questions: Record<string, any>[] = [];

  selectedQuestions: number[] = [];

  accommodationClone: AccommodationDto = new AccommodationDto();

  accommodationIndemnity: IIndemnity[] = [
    {
      name: '',
      form: '',
    },
  ] as IIndemnity[];

  directions = { url: '' } as any;

  editDirections: { url: ''; name: '' }[] | null = null;

  editedAccomodation: AccommodationDto;

  step = 0;

  singleStepSubmit: boolean | null = true;

  @Watch('currentStep')
  checkStep(newStep: number, oldStep: number) {
    /** i keep a copy of the filledin accom form on submit
    and use that to fill if they go back a step */
    if (newStep == 0 && newStep < oldStep) {
      this.$nextTick(() => {
        this.accommodationClone = this.editedAccomodation;
        /*         this.detailsSchema[0].children[0].value.url = '/path/to/document.pdf';
         */
      });
    }
  }

  changeStep(incr: string) {
    if (incr === 'incr') {
      this.syncedCurrentStep += 1;
    } else if (incr === 'dcr') {
      this.syncedCurrentStep -= 1;
    } else {
      return;
    }
  }

  get schema() {
    return [
      {
        component: 'div',
        class: 'grid grid-cols-2 gap-2 shadow-lg px-5 py-5 rounded-lg',
        'keep-model-data': true,

        children: [
          {
            type: 'image',
            name: 'file',
            id: Math.random().toString(),
            // 'upload-url': '/your/upload/directory',
            label: "Upload your accommodation's logo:",
            // placeholder: 'Accommodation name',
            // help: 'Select a png, jpg or gif to upload.',
            validation: 'required',
            'upload-behavior': 'delayed',
            value: this.syncedLogo,
            'keep-model-data': true,

            // validation: 'mime:image/jpeg,image/png,image/gif',
            class: 'col-span-2',
          },

          {
            type: 'text',
            name: 'name',
            label: 'Accommodation name',
            placeholder: 'Accommodation name',
            validation: 'required',
            'validation-name': 'Accommodation Name',
            'keep-model-data': true,
            class: 'col-span-1',
          },
          {
            type: 'select',
            label: 'Type of accommodation',
            name: 'typeId',
            validation: 'required',
            'validation-name': 'Type',
            'keep-model-data': true,
            class: 'col-span-1',
            options: appModule.accommodationType.map((v: any) => {
              return { value: v.id, label: v.name };
            }),
          },
          {
            name: 'contactPerson',
            label: `Contact person's name`,
            validation: 'required',
            placeholder: 'Contact person',
            'validation-name': 'Contact person',
            'keep-model-data': true,
            class: 'col-span-1',
          },
          {
            type: 'email',
            name: 'email',
            label: 'Email',
            placeholder: 'Notification email',
            validation: 'required|email',
            'validation-name': 'Email',
            'keep-model-data': true,
            class: 'col-span-1',
          },
          {
            type: 'countryCode',
            label: 'Phone number',
            name: 'phone',
            'keep-model-data': true,
            'validation-messages': {
              phone: 'Phone is required',
            },
            'error-behaviour': 'blur',
            validation: 'required|phone',
          },
          {
            type: 'countryCode',
            label: 'Emergency phone number',
            name: 'emergencyNumber',
            'keep-model-data': true,
            'validation-messages': {
              phone: 'Phone is required',
            },
            validation: 'required|phone',
            'validation-behaviour': 'live',
          },
          {
            component: 'div',
            'keep-model-data': true,

            class: 'col-span-2 flex gap-2 pr-2',
            children: [
              {
                type: 'number',
                name: 'rooms',
                class: 'col-span-2 w-1/2',
                label: 'Number of rooms',
                validation: 'min:1,value',
                value: '1',
                'keep-model-data': true,
                'validation-name': 'Total rooms',
              },
            ],
          },

          {
            component: 'div',
            'keep-model-data': true,

            class: 'col-span-1 flex',
            children: [
              {
                type: 'timepicker',
                name: 'checkInTimeStart',
                pattern: '[0-9]*',
                class: 'col-span-1 flex',
                label: 'Check-in from',
                validation: 'required',
                'keep-model-data': true,
                'validation-name': 'Check-in time',
              },
              {
                type: 'timepicker',
                name: 'checkInTimeEnd',
                label: 'To',
                class: 'col-span-1 flex gap-2',
                pattern: '[0-9]*',
                'keep-model-data': true,
              },
            ],
          },
          {
            type: 'timepicker',
            label: 'Check-out time',
            name: 'checkOutTime',
            // pattern: '[0-9]*',
            validation: 'required',
            'keep-model-data': true,
            'validation-name': 'Check-out time',
            class: 'col-span-1 w-1/2 flex',
          },
        ],
      },
    ];
  }

  resetForm() {
    this.$formulate.reset('details');
  }

  created() {
    this.accommodationClone = this.syncedAccommodation;
  }

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  get uploadUrl(): string | undefined {
    return this.baseUrl;
  }

  validate(form: FormulateForm, args: string): boolean {
    return form?.formSubmitted() && args !== '';
  }
  event(e: any) {
    console.log(e);
  }

  cancel() {
    this.$emit('cancel-creation');
  }

  submitController(stepperSubmit: boolean, index: number) {
    this.$formulate.submit('details');
    if (stepperSubmit === null) return (this.singleStepSubmit = null);
    if (stepperSubmit) {
      this.singleStepSubmit = false;
      this.step = index;
    }
  }

  submitHandler(data: any): any {
    this.editedAccomodation = data;
    this.detailsSchema[0].children[1].value = data.file;
    this.syncedAccommodation = data;
    this.syncedLogo = data.file;
    if (this.singleStepSubmit === false) {
      this.singleStepSubmit = true;
      this.syncedCurrentStep = this.step;
    } else if (this.singleStepSubmit) {
      this.syncedCurrentStep += 1;
    } else {
      this.$emit('data-saved');
    }
  }

  getFormOptions(options: Record<string, any>[]) {
    if (options) return _.omit(_.omit(options, 'optionGroupId'), 'optionGroupId');
  }
}
