
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { mixins } from 'vue-class-component';
import Upload from '@/components/Upload.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import Tinymce from '@/components/Tinymce/index.vue';
import { Auth } from '@/store/modules/auth.module';
import * as _ from 'lodash';
import attachment from '@/components/attachment/attachment.vue';
import AccommodationSchema from '@/modules/accommodation/schema/accommodation.schema';

import { AccommodationDtoDocuments } from '../../../app/entities/tenant/accommodation.dto';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({
  components: {
    Upload,
    Tinymce,
    attachment,
  },
})
export default class IndemnitiesAndTerms extends mixins(AccommodationSchema) {
  @Ref('form') readonly form: any;

  @Ref('detailsForm') readonly detailsForm: FormulateForm;

  @Ref('healthDisclaimerForm') readonly healthDisclaimerForm: FormulateForm;

  @Ref('documentForm') documentForm: any;

  @PropSync('active') activeSync!: boolean;

  @PropSync('currentStep') syncedCurrentStep!: number;

  @PropSync('accommodationIndemnity') syncedAccommodationIndemnity!: any;

  @PropSync('accommodation') syncedAccommodation!: any;

  @Prop({ default: () => null }) accommodationEdit: any;

  $refs!: any;

  baseUrl = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/upload/`;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  // changeStep(incr: string) {
  //   incr === 'incr' ? (this.syncedCurrentStep += 1) : (this.syncedCurrentStep -= 1);
  // }

  singleDocument: any = {
    attachEmail: false,
  };

  isLoading = false;

  editingIndex: number | null = null;

  _ = _;

  accomIndemName = '';
  accomIndemForm = '';

  changeStep(incr: string) {
    if (incr === 'incr') {
      this.syncedCurrentStep += 1;
    } else if (incr === 'dcr') {
      this.syncedCurrentStep -= 1;
    } else {
      return;
    }
  }

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  addIndemnity() {
    if (this.accomIndemName === '') {
      this.$swal('No Document Title', 'Please add a title', 'error');
      return;
    }
    if (this.accomIndemForm === '') {
      this.$swal('No Document Text Content', 'Please add text content', 'error');
      return;
    }

    if (this.editingIndex !== null) {
      this.syncedAccommodationIndemnity.splice(this.editingIndex, 1);
      this.syncedAccommodationIndemnity.splice(this.editingIndex, 0, { name: this.accomIndemName, form: this.accomIndemForm });
      this.$swal(`${this.accomIndemName} saved`, 'Your edits have been saved', 'success');
      this.accomIndemName = '';
      this.accomIndemForm = '';
      this.editingIndex = null;
    } else {
      this.syncedAccommodationIndemnity.push({ name: this.accomIndemName, form: this.accomIndemForm });
      this.$swal(`${this.accomIndemName} added`, 'Your document has been saved', 'success');
      this.accomIndemName = '';
      this.accomIndemForm = '';
    }
  }

  editDoc(index: number): void {
    this.accomIndemName = this.syncedAccommodationIndemnity[index].name;
    this.accomIndemForm = this.syncedAccommodationIndemnity[index].form;
    document.getElementById('new-accommodation-stepper')?.scrollIntoView({ behavior: 'smooth' });
    this.editingIndex = index;
  }

  removeIndemnity(index: number): void {
    this.syncedAccommodationIndemnity.splice(index, 1);
  }

  validateNext(stepperSubmit: boolean, index: number) {
    if (this.syncedAccommodationIndemnity.length >= 1) {
      if (stepperSubmit) {
        this.syncedCurrentStep = index;
      } else if (!stepperSubmit && stepperSubmit !== null) {
        this.syncedCurrentStep += 1;
      } else {
        this.$emit('data-saved');
      }
    } else {
      if (this.accomIndemName === '') {
        this.$swal('No Terms or Indemnity', 'Please add at least one indemnity', 'error');
        return;
      } else if (this.accomIndemForm === '') {
        this.$swal('No Body', 'Please fill in the body of the form', 'error');
        return;
      }
    }
  }

  public success(event: any): void {
    this.syncedAccommodation.logo = event[0].data;
  }
}
