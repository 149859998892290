import { submit } from '@/shared/schema';
import Vue from 'vue';
import Component from 'vue-class-component';
import { appModule } from '../../../store/modules/app.module';

@Component
export default class AccommodationSchema extends Vue {
  detailsSchema = [
    {
      component: 'div',
      class: 'grid grid-cols-2 gap-2',
      'keep-model-data': true,
      children: [
        {
          type: 'image',
          name: 'file',
          // 'upload-url': '/your/upload/directory',
          label: "Upload your accommodation's logo:",
          // placeholder: 'Accommodation name',
          // help: 'Select a png, jpg or gif to upload.',
          validation: 'required',
          'upload-behavior': 'delayed',
          /*           value: [
            {
              url: '/path/to/document.pdf', // url is required
              name: 'employment-offer.pdf' // name is optional
            }
          ], */

          // validation: 'mime:image/jpeg,image/png,image/gif',
          class: 'col-span-1',
          '@file-upload-complete': 'upload-complete',
        },
        {
          type: 'select',
          label: 'Type of accommodation',
          name: 'typeId',
          validation: 'required',
          'validation-name': 'Type',
          'keep-model-data': true,

          class: 'col-span-1',
          options: appModule.accommodationType.map((v: any) => {
            return { value: v.id, label: v.name };
          }),
        },
        {
          type: 'text',
          name: 'name',
          label: 'Accommodation name',
          placeholder: 'Accommodation name',
          validation: 'required',
          'validation-name': 'Accommodation Name',
          'keep-model-data': true,
          class: 'col-span-1',
        },
        {
          type: 'email',
          name: 'email',
          label: 'Email',
          placeholder: 'Notification email',
          validation: 'required|email',
          'validation-name': 'Email',
          'keep-model-data': true,
          class: 'col-span-1',
        },
        {
          name: 'contactPerson',
          label: 'Contact person',
          validation: 'required',
          placeholder: 'Contact person',
          'validation-name': 'Contact person',
          class: 'col-span-1',
          'keep-model-data': true,
        },
        {
          type: 'number',
          name: 'rooms',
          class: 'col-span-1 w-1/2',
          label: 'Number of rooms',
          validation: 'min:1,value',
          value: '1',
          'keep-model-data': true,
          'validation-name': 'Total rooms',
        },
        {
          type: 'countryCode',
          label: 'Phone number',
          name: 'phone',
          validation: 'required',
          required: true,
          'keep-model-data': true,
        },
        {
          type: 'countryCode',
          label: 'Emergency number',
          name: 'emergencyNumber',
          validation: 'required',
          required: true,
          'keep-model-data': true,
        },

        {
          component: 'div',
          'keep-model-data': true,
          class: 'col-span-1 flex gap-2 pr-2',
          children: [
            {
              type: 'timepicker',
              name: 'checkInTimeStart',
              pattern: '[0-9]*',
              class: 'col-span-1 w-1/2 flex gap-2',
              label: 'Check-in from',
              validation: 'required',
              'keep-model-data': true,
              'validation-name': 'Check-in time',
            },
            {
              type: 'timepicker',
              name: 'checkInTimeEnd',
              label: 'To',
              class: 'col-span-1 w-1/2 flex gap-2 ',
              pattern: '[0-9]*',
              'keep-model-data': true,
            },
          ],
        },
        {
          type: 'timepicker',
          label: 'Check-out time',
          name: 'checkOutTime',
          // pattern: '[0-9]*',
          validation: 'required',
          'keep-model-data': true,
          'validation-name': 'Check-out time',

          class: 'col-span-1 w-1/2 flex gap-2',
        },
      ],
    },
  ];

  healthDisclaimerSchema = [
    {
      type: 'textarea',
      name: 'healthDisclaimer',
      label: 'Provide a description and/or disclaimer for the guest health screening questionnaire:',
      rows: 8,
      validation: 'required',
      'validation-name': 'Health screening disclaimer',
      'keep-model-data': true,
      class: 'w-full mb-3',
    },
  ];

  documentSchema = [
    {
      component: 'div',
      class: 'form-wrap',
      children: [
        // {
        //   component: 'vs-divider',
        //   children: 'Upload documents',
        //   class: 'col-span-2',
        // },
        {
          type: 'text',
          label: 'Title',
          name: 'name',
          validation: 'required',
          placeholder: '',
        },
        {
          type: 'select',
          label: 'Type of document',
          name: 'type',
          'input-class': 'dark:bg-gray-500 dark:text-white',
          options: [
            'Restaurant menu',
            'Drinks menu',
            'Spa Menu',
            'Activities',
            'Room Guide',
            'Emergency details',
            'Map and directions',
            'COVID19 Protocols',
            'Quick facts',
            'Specials',
          ],
        },
        {
          type: 'file',
          name: 'file',
          class: 'mb-4',
          label: 'Select an file to upload',
          placeholder: 'Accommodation name',
          help: 'Select a png, jpg or pdf to upload.',
          'keep-model-data': true,
          '@file-upload-complete': true,
        },
      ],
    },
    // {
    //   type: 'submit',
    //   label: 'Save document',
    //   class: 'flex justify-end relative mt-3 rounded-lg shadow-sm font-light',
    // },
  ];

  uploadSchema = [
    {
      component: 'div',
      class: 'form-wrap',
      children: [
        {
          type: 'file',
          name: 'file',
          class: 'mb-4',
          label: 'Directions',
          placeholder: 'Directions',
          help: 'Optionally add any directions to send to your guest here.',
          'keep-model-data': true,
          '@file-upload-complete': true,
        },
      ],
    },
  ];
}
