
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { mixins } from 'vue-class-component';
import Upload from '@/components/Upload.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Auth } from '@/store/modules/auth.module';
import * as _ from 'lodash';
import attachment from '@/components/attachment/attachment.vue';

import AccommodationSchema from '@/modules/accommodation/schema/accommodation.schema';

import { AccommodationDto } from '../../../app/entities/tenant/accommodation.dto';

import CustomFormModal from '@/components/customFormModal/CustomFormModal.vue';

import CustomiseFormQuestions from '@/modules/accommodation/schema/customise.form.schema';

interface FormulateForm {
  formSubmitted(): boolean;
}

export interface CustomQuestions {
  sectionTitle: string;
  sectionDescription: string;
  questions: QuestionsEntity[];
}
export interface QuestionsEntity {
  question: string;
  name: string;
  id: number;
  required?: boolean | null;
}

@Component({
  components: {
    Upload,
    CustomFormModal,
    attachment,
  },
})
export default class CustomiseForm extends mixins(AccommodationSchema) {
  @Ref('form') readonly form: any;

  @Ref('detailsForm') readonly detailsForm: FormulateForm;

  @Ref('healthDisclaimerForm') readonly healthDisclaimerForm: FormulateForm;

  @Ref('documentForm') documentForm: any;

  @PropSync('active') activeSync!: boolean;

  @PropSync('selectedCustomQuestions') syncedSelectedCustomQuestions!: any;

  @PropSync('accommodation') syncedAccommodation!: any;

  @PropSync('currentStep') syncedCurrentStep!: number;

  @Prop({ default: () => null }) accommodationEdit: any;

  $refs!: any;

  baseUrl = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/upload/`;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  logo = [];

  singleDocument: any = {
    attachEmail: false,
  };

  isLoading = false;

  _ = _;

  formPopupActive = false;

  questions: Record<string, any>[] = [];

  customQuestions: CustomQuestions[] = [];

  questionIds: number[] = [];

  disabledQuestions: string[] = [];

  editedAccomodation: AccommodationDto;

  @Watch('currentStep')
  checkStep(newStep: number, oldStep: number) {
    /** i keep a copy of the filledin accom form on submit
    and use that to fill if they go back a step */
    if (newStep == 0 && newStep < oldStep) {
      this.$nextTick(() => {
        this.syncedAccommodation = this.editedAccomodation;
        /*         this.detailsSchema[0].children[0].value.url = '/path/to/document.pdf';
         */
      });
    }
  }

  created() {
    this.customQuestions = CustomiseFormQuestions;
    if (this.syncedAccommodation.guestConfirmation.confirmationSchema !== undefined) {
      this.syncedAccommodation.guestConfirmation.confirmationSchema.find((element: any) => {
        this.syncedSelectedCustomQuestions.push(element);
      });
    }
    this.customQuestions.forEach((section) => {
      section.questions.find((question: any) => {
        if (question.required) this.disabledQuestions.push(question.name);
      });
      section.questions.forEach((question: any) => {
        if (
          this.syncedAccommodation.guestConfirmation.confirmationSchema !== undefined &&
          this.syncedAccommodation.guestConfirmation.confirmationSchema.includes(question.name)
        ) {
          return;
        } else this.questionIds.push(question.id);
      });
    });
  }

  openForm() {
    this.$refs.customFormModal.removeOptionFromForm(this.syncedSelectedCustomQuestions);
    this.formPopupActive = true;
  }

  hasQuestion(id: number) {
    return this.questionIds.some((key: number) => key === id);
  }

  addRemove(id: number, name: string) {
    const index = this.questionIds.findIndex((key) => key === id);
    if (index >= 0) {
      this.questionIds = this.questionIds.filter((ele) => ele !== id);
      this.syncedSelectedCustomQuestions.push(name);
    } else {
      this.questionIds.push(id);
      this.syncedSelectedCustomQuestions.find((questionName: string) => {
        if (questionName === name) {
          this.syncedSelectedCustomQuestions.splice(this.syncedSelectedCustomQuestions.indexOf(questionName), 1);
        }
      });
    }
  }

  changeStep(incr: string) {
    window.scrollTo(0, 0);
    if (incr === 'incr') {
      this.syncedCurrentStep += 1;
    } else if (incr === 'dcr') {
      this.syncedCurrentStep -= 1;
    } else {
      return;
    }
  }

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  validate(form: FormulateForm, args: string): boolean {
    return form?.formSubmitted() && args !== '';
  }

  // submitHandler(data: any): any {
  //   this.editedAccomodation = data;
  //   this.detailsSchema[0].children[1].value = data.file;
  //   this.syncedAccommodation = data;
  //   this.syncedAccommodation.healthDisclaimer = data;
  //   this.logo = data.file;
  //   this.syncedCurrentStep += 1;
  // }

  getFormOptions(options: Record<string, any>[]) {
    if (options) return _.omit(_.omit(options, 'optionGroupId'), 'optionGroupId');
  }
}
