
// Docs: https://www.tiny.cloud/docs/advanced/usage-with-module-loaders/
// Import TinyMCE
import 'tinymce/tinymce';
// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';
// Import themes
import 'tinymce/themes/silver';
import 'tinymce/themes/mobile';
// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/print';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/tabfocus';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';
import Editor from '@tinymce/tinymce-vue';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';

const defaultId = () => `vue-tinymce-${+new Date()}${(Math.random() * 1000).toFixed(0)}`;

@Component({
  name: 'Tinymce',
  components: {
    // EditorImageUpload,
    Editor,
  },
})
export default class extends Vue {
  @PropSync('value') valueSync: string;

  @Prop({ default: defaultId }) private id!: string;

  @Prop({ default: () => [] }) private toolbar!: string[];

  @Prop({ default: 'file edit insert view format table' }) private menubar!: string;

  @Prop({ default: '360px' }) private height!: string | number;

  @Prop({ default: 'auto' }) private width!: string | number;

  tinymce: any = {};

  tinyScript = null as any;

  hasChange = false;

  hasInit = false;

  fullscreen = true;

  editor = null as any;

  get containerWidth() {
    const { width } = this;
    // Test matches `100`, `'100'`
    if (/^[\d]+(\.[\d]+)?$/.test(width.toString())) {
      return `${width}px`;
    }
    return width;
  }

  get initOptions() {
    return {
      height: 500,
      plugins: 'preview casechange export formatpainter lists checklist  permanentpen powerpaste table advtable',
      toolbar: [
        'searchreplace bold italic preview underline  alignleft aligncenter alignright outdent indent blockquote undo redo removeformat numlist bullist ',
      ],
      toolbar_mode: 'floating',
      skin_url: `https://handycatssa.blob.core.windows.net/tinymce/tinymce/skins`,
    };
  }
}
