
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import { mixins } from 'vue-class-component';
import Upload from '@/components/Upload.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import GoogleMapInput from '@/components/google-map/GoogleMapInput.vue';
import { Auth } from '@/store/modules/auth.module';
import * as _ from 'lodash';
import attachment from '@/components/attachment/attachment.vue';
import AccommodationSchema from '@/modules/accommodation/schema/accommodation.schema';
import { AccommodationDto, AccommodationDtoDocuments, IIndemnity } from '../../../app/entities/tenant/accommodation.dto';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({
  components: {
    Upload,

    GoogleMapInput,

    attachment,
  },
})
export default class Location extends mixins(AccommodationSchema) {
  @Ref('detailsForm') readonly detailsForm: FormulateForm;

  @Ref('documentForm') documentForm: any;

  @PropSync('active') activeSync!: boolean;

  @PropSync('currentStep') syncedCurrentStep!: number;

  @PropSync('accommodation') syncedAccommodation!: any;

  @PropSync('directions') syncedDirections!: any;

  @Prop({ default: () => null }) accommodationEdit: any;

  $refs!: any;

  baseUrl = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/upload/`;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  logo = [];

  // changeStep(incr: string) {
  //   incr === 'incr' ? (this.syncedCurrentStep += 1) : (this.syncedCurrentStep -= 1);
  // }

  changeStep(incr: string) {
    if (incr === 'incr') {
      this.syncedCurrentStep += 1;
    } else if (incr === 'dcr') {
      this.syncedCurrentStep -= 1;
    } else {
      return;
    }
  }

  singleDocument: any = {
    attachEmail: false,
  };

  isLoading = false;

  _ = _;

  isOn = false;

  questions: Record<string, any>[] = [];

  selectedQuestions: number[] = [];

  accommodationIndemnity: IIndemnity[] = [
    {
      name: '',
      form: '',
    },
  ] as IIndemnity[];

  editDirections: { url: ''; name: '' }[] | null = null;

  editedAccomodation: AccommodationDto;

  @Watch('currentStep')
  checkStep(newStep: number, oldStep: number) {
    /** i keep a copy of the filledin accom form on submit
    and use that to fill if they go back a step */
    if (newStep == 0 && newStep < oldStep) {
      this.$nextTick(() => {
        this.syncedAccommodation = this.editedAccomodation;
        /*         this.detailsSchema[0].children[0].value.url = '/path/to/document.pdf';
         */
      });
    }
  }

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  get uploadUrl(): string | undefined {
    return this.baseUrl;
  }

  get isMember() {
    return !this.$ability.can('create', 'accommodation');
  }

  validate(form: FormulateForm, args: string): boolean {
    return form?.formSubmitted() && args !== '';
  }

  // validateNext() {
  //   if (this.accommodation.address.name === '') {
  //     this.$swal('No Address', 'Please add an address', 'error');
  //     return;
  //   } else {
  //     this.syncedCurrentStep += 1;
  //   }
  // }
  

  validateNext(): any {
    if (this.syncedAccommodation.address.name === '') {
      this.$swal('No Address', 'Please add an address', 'error');
      return;
    } else {
      this.syncedCurrentStep += 1;
    }
  }

  // documentSubmitHandler(val: any): any {
  //   const docObj = val;
  //   const url = val.file[0].url;
  //   docObj.url = url;
  //   const doc = new AccommodationDtoDocuments(docObj);
  //   this.syncedAccommodation.accommodationDocuments.push(doc);
  //   this.$formulate.reset('f-1');
  //   this.singleDocument = {
  //     attachEmail: false,
  //   };
  // }

  deleteRow(e: any) {
    const index = this.syncedAccommodation.accommodationDocuments.indexOf(e);
    this.syncedAccommodation.accommodationDocuments.splice(index, 1);
  }

  public success(event: any): void {
    this.syncedAccommodation.logo = event[0].data;
  }

  getFormOptions(options: Record<string, any>[]) {
    if (options) return _.omit(_.omit(options, 'optionGroupId'), 'optionGroupId');
  }
}
