
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Ref } from 'vue-property-decorator';
import { customPrint } from '@/app/utils/print';

import { GuestBookingSchema } from '@/modules/guests/schema/guest-booking.schema';

@Component
export default class CustomFormModal extends Vue {
  @PropSync('formPopupActive') check: any;

  schema: any = [];

  removeOptionFromForm(fieldsToRemove: string[]) {
    this.schema = GuestBookingSchema();
    if (fieldsToRemove.includes('arrivalTime')) {
      fieldsToRemove.splice(fieldsToRemove.indexOf('arrivalTime'), 1);
      fieldsToRemove.unshift('arrivalTimeEnd', 'arrivalTimeStart');
    }
    fieldsToRemove.forEach((nameOfField: string) => {
      this.schema.forEach((el: any) => {
        if (el.children) {
          el.children.forEach((child: any) => {
            if (child.children) {
              child.children.forEach((subChild: any) => {
                if (subChild.name === nameOfField) {
                  child.children.splice(child.children.indexOf(subChild), 1);
                }
              });
            } else if (child.name === nameOfField) {
              el.children.splice(el.children.indexOf(child), 1);
            } else return;

            if (child.children && child.children.length === 0) {
              el.children.splice(el.children.indexOf(child), 1);
            }
          });
        } else {
          if (el.options) {
            let optionsChildren = el.options[0].children;

            optionsChildren.forEach((child: any) => {
              if (child.name === nameOfField || child.name.includes(nameOfField)) {
                optionsChildren.splice(optionsChildren.indexOf(child), 1);
              }
            });
            // Clean up description and separator and set validation to true
            if (el.name === 'profile' && el.options[0].children.length === 0) {
              const profileHR = this.schema.find((div: any) => {
                return div.name === 'profileHR';
              });
              this.schema.splice(this.schema.indexOf(profileHR), 3);
            }
            if (el.name === 'guestArrival' && el.options[0].children.length === 0) {
              const guestArrivalHR = this.schema.find((div: any) => {
                return div.name === 'guestArrivalHR';
              });
              this.schema.splice(this.schema.indexOf(guestArrivalHR), 3);
            }
            if (el.name === 'guestAdditionalInformation' && el.options[0].children.length === 0) {
              const additionalInformationHR = this.schema.find((div: any) => {
                return div.name === 'additionalInformationHR';
              });
              this.schema.splice(this.schema.indexOf(additionalInformationHR), 3);
            }
          }
        }
      });
    });
  }

  print() {
    customPrint('printText', {
      name: '_blank',
      specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
      styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',
        'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
      ],
      timeout: 1000,
      autoClose: true,
      windowTitle: window.document.title,
    });
  }

  closeModal() {
    this.check = false;
  }
}
