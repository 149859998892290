export default [
  {
    sectionTitle: 'Personal Information',
    sectionDescription: 'Select personal questions you need your guests to complete when they check-in.',
    questions: [
      {
        question: 'First name',
        name: 'firstName',
        id: 0,
        required: true,
      },
      {
        question: 'Last name',
        name: 'lastName',
        id: 1,
        required: true,
      },
      {
        question: 'Email',
        name: 'email',
        id: 2,
        required: true,
      },
      {
        question: 'Phone number',
        name: 'phone',
        id: 3,
        required: true,
      },
      {
        question: 'Residential address',
        name: 'address',
        id: 4,
      },
      {
        question: 'Residency status',
        name: 'resident',
        id: 5,
      },
      {
        question: 'Nationality',
        name: 'nationality',
        id: 6,
      },
      {
        question: 'Passport or ID number',
        name: 'passportId',
        id: 7,
      },
      {
        question: 'Date of birth',
        name: 'dateOfBirth',
        id: 8,
      },
      {
        question: 'Upload ID or travel document',
        name: 'travelIdDocument',
        id: 9,
      },
    ],
  },
  {
    sectionTitle: 'Medical and Health Information',
    sectionDescription: 'Select medical and health questions you need your guests to complete when they check-in.',
    questions: [
      {
        question: 'Do you have travel insurance',
        name: 'travelInsurance',
        id: 10,
      },
      {
        question: 'Medical aid provider',
        name: 'medicalAidProvider',
        id: 11,
      },
      {
        question: 'Medical aid policy number',
        name: 'medicalAidNumber',
        id: 12,
      },
      {
        question: 'Medical aid emergency number',
        name: 'medicalAidEmergency',
        id: 13,
      },
      {
        question: 'Next of kin contact number',
        name: 'emergencyContact',
        id: 14,
      },
      {
        question: 'Dietry requirements',
        name: 'dietaryRequirements',
        id: 15,
      },
      {
        question: 'Allergies', // needs to be added to schema
        name: 'allergies',
        id: 16,
      },
      {
        question: 'Existing medical conditions', // needs to be added to schema
        name: 'medicalConditions',
        id: 17,
      },
      {
        question: 'Do you have any special accessibility requirements?', // needs to be added to schema
        name: 'specialRequirements',
        id: 18,
      },
      {
        question: 'Are you vaccinated against COVID-19?', // needs to be added to schema
        name: 'vaccinated',
        id: 19,
      },
      {
        question: 'Is your COVID-19 vaccines up to date?', // needs to be added to schema
        name: 'vaccineUpToDate',
        id: 20,
      },
      {
        question: 'Upload proof of vaccination', // needs to be added to schema
        name: 'vaccinationProof',
        id: 21,
      },
    ],
  },
  {
    sectionTitle: 'Arrival Information',
    sectionDescription: 'Select questions you need your guests to answer about their arrival when they check-in.',
    questions: [
      {
        question: 'Luggage handling',
        name: 'luggageHandling',
        id: 22,
      },
      {
        question: 'Approximate arrival time', // covers start and end?
        name: 'arrivalTime',
        id: 23,
      },
      {
        question: 'Mode of transport',
        name: 'modeTransport',
        id: 24,
      },
      {
        question: 'Vehicle registration',
        name: 'vehicleRegistration',
        id: 25,
      },
      {
        question: 'Flight number', // needs to be added to schema
        name: 'flightNumber',
        id: 26,
      },
      {
        question: 'Airline', // needs to be added to schema
        name: 'airline',
        id: 27,
      },
    ],
  },
  {
    sectionTitle: 'Additional Information',
    sectionDescription: 'Select any additional questions you need your guests to complete when they check-in.',
    questions: [
      {
        question: 'Bed configuration (Singles or King Size)', // needs to be added to schema
        name: 'bedConfig',
        id: 28,
      },
      {
        question: 'Which guests will you be sharing a room with?', // needs to be added to schema
        name: 'roomSharing',
        id: 29,
      },
      {
        question: 'Do you require cots for infants?', // needs to be added to schema
        name: 'infantCots',
        id: 30,
      },
      {
        question: 'Would you like a bush dinner?', // needs to be added to schema
        name: 'bushDinner',
        id: 31,
      },
      {
        question: 'Would you like to do a bush walk?', // needs to be added to schema
        name: 'bushWalk',
        id: 32,
      },
      {
        question: 'Are you celebrating a special occasion?', // needs to be added to schema
        name: 'specialOccasion',
        id: 33,
      },
      {
        question: 'Any specific interests - i.e. Birding, Photography, Fauna & Flora, Culture, History', // needs to be added to schema
        name: 'specificInterests',
        id: 34,
      },
      {
        question: 'Will you be carrying a firearm with you?', // needs to be added to schema
        name: 'firearm',
        id: 35,
      },
    ],
  },
];
