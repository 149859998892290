
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { mixins } from 'vue-class-component';
import Upload from '@/components/Upload.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Loading } from '@/modules/auth/store/auth.service';
import { Auth } from '@/store/modules/auth.module';
import * as _ from 'lodash';
import attachment from '@/components/attachment/attachment.vue';
import { isEmpty } from 'lodash';
import AccommodationSchema from '@/modules/accommodation/schema/accommodation.schema';
import { getHashMap } from '@/app/utils/array';
import { AccommodationDto, AccommodationDtoDocuments, IIndemnity } from '../../../app/entities/tenant/accommodation.dto';
import { appModule } from '../../../store/modules/app.module';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({
  components: {
    Upload,
    attachment,
  },
})
export default class HealthQuestionnaire extends mixins(AccommodationSchema) {
  @Ref('form') readonly form: any;

  @Ref('detailsForm') readonly detailsForm: FormulateForm;

  @Ref('healthDisclaimerForm') readonly healthDisclaimerForm: FormulateForm;

  @Ref('documentForm') documentForm: any;

  @PropSync('active') activeSync!: boolean;

  @PropSync('currentStep') syncedCurrentStep!: number;

  @PropSync('accommodation') syncedAccommodation!: any;

  @PropSync('questionsRequired') syncedQuestionsRequired!: boolean;

  // @PropSync('questions') syncedQuestions: Record<string, any>[] = [];

  @PropSync('selectedQuestions') syncedSelectedQuestions!: any;

  @PropSync('isEditing') syncedisEditing!: boolean;

  @Prop() accommodationEdit: any;

  $refs!: any;

  baseUrl = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/upload/`;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  logo = [];

  // changeStep(incr: string) {
  //   incr === 'incr' ? (this.syncedCurrentStep += 1) : (this.syncedCurrentStep -= 1);
  // }

  changeStep(incr: string) {
    if (incr === 'incr') {
      this.syncedCurrentStep += 1;
    } else if (incr === 'dcr') {
      this.syncedCurrentStep -= 1;
    } else {
      return;
    }
  }

  singleDocument: any = {
    attachEmail: false,
  };

  isLoading = false;

  _ = _;

  isOn = false;

  activeDoc = 0;

  questions: Record<string, any>[] = [];

  accommodationIndemnity: IIndemnity[] = [
    {
      name: '',
      form: '',
    },
  ] as IIndemnity[];

  editedAccomodation: AccommodationDto;

  @Watch('currentStep')
  checkStep(newStep: number, oldStep: number) {
    /** i keep a copy of the filledin accom form on submit
    and use that to fill if they go back a step */
    if (newStep == 0 && newStep < oldStep) {
      this.$nextTick(() => {
        this.syncedAccommodation = this.editedAccomodation;
        /*         this.detailsSchema[0].children[0].value.url = '/path/to/document.pdf';
         */
      });
    }
  }

  hasQuestion(id: number) {
    return this.syncedSelectedQuestions.some((key: number) => key === id);
  }

  addRemove(id: number) {
    const index = this.syncedSelectedQuestions.findIndex((key: number) => key === id);

    if (index >= 0) {
      this.syncedSelectedQuestions = this.syncedSelectedQuestions.filter((ele: number) => ele !== id);
      this.syncedSelectedQuestions.sort((a: number, b: number) => a - b);
      console.log('syncedSelectedQuestions');
      console.log(this.syncedSelectedQuestions);
    } else {
      this.syncedSelectedQuestions.push(id);
      // console.log('syncedSelectedQuestions');
      this.syncedSelectedQuestions.sort((a: number, b: number) => a - b);
      // console.log(this.syncedSelectedQuestions.sort((a: number, b: number) => a - b));
      console.log(this.syncedSelectedQuestions);
    }
  }

  @Watch('syncedSelectedQuestions')
  toggleRequireButton() {
    if (this.syncedSelectedQuestions.length === 0) {
      this.syncedQuestionsRequired = false;
      this.syncedAccommodation.healthDisclaimer = '';
    } else this.syncedQuestionsRequired = true;
  }

  AddRemoveAll() {
    if (this.syncedQuestionsRequired) {
      this.syncedSelectedQuestions = [];
      this.syncedAccommodation.healthDisclaimer = '';
    } else if (!this.syncedQuestionsRequired) {
      this.syncedSelectedQuestions = this.questions.map((question) => question.id);
    }
    this.syncedQuestionsRequired = !this.syncedQuestionsRequired;
  }

  created() {
    this.questions = appModule.questions;
    if (!isEmpty(this.accommodationEdit)) {
      this.syncedSelectedQuestions = this.syncedAccommodation.healthQuestions.map((question: any) => question.questionId);
    } else {
      this.syncedAccommodation.healthDisclaimer = '';
      this.syncedSelectedQuestions = this.questions.map((question) => question.id);
    }
  }

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  validate(form: FormulateForm, args: string): boolean {
    return form?.formSubmitted() && args !== '';
  }

  validateNext(stepperSubmit: boolean, index: number) {
    if (this.syncedAccommodation.healthDisclaimer === '' && this.syncedQuestionsRequired === true) {
      this.$swal('No Disclaimer', 'Please add a disclaimer for your health questionnaire.', 'error');
      return;
    } else {
      if (stepperSubmit) {
        this.syncedCurrentStep = index;
      } else if (!stepperSubmit && stepperSubmit !== null) {
        window.scrollTo(0, 0);
        this.syncedCurrentStep += 1;
      } else {
        this.$emit('data-saved');
      }
    }
  }

  // submitHandler(data: any): any {
  //   // this.editedAccomodation = data;
  //   // this.detailsSchema[0].children[1].value = data.file;
  //   // this.syncedAccommodation = data;
  //   this.syncedAccommodation.healthDisclaimer = data;
  //   // this.logo = data.file;
  //   this.syncedCurrentStep += 1;
  // }

  public success(event: any): void {
    this.syncedAccommodation.logo = event[0].data;
  }

  getFormOptions(options: Record<string, any>[]) {
    if (options) return _.omit(_.omit(options, 'optionGroupId'), 'optionGroupId');
  }
}
