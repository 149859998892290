
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { uploadNotify, deleteNotify, errorNotify } from '@/shared/constants/notifications';
import service from '@/services/app-http-client';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Auth } from '@/store/modules/auth.module';
import { AccommodationDto, AccommodationDtoDocuments } from '@/app/entities/tenant/accommodation.dto';

@Component({})
export default class Upload extends Vue {
  active = true;

  @PropSync('accommodation') accommodationSync!: AccommodationDto;

  @PropSync('uploads') uploadsSync!: AccommodationDtoDocuments[];

  @Prop() id!: number;

  @Prop() type!: string;

  @Prop() accepted!: [];

  @Prop({ default: 'list' }) theme!: string;

  files: any = [];

  pdf = 'https://www.irislink.com/Documents/Image/_IrisLink2.0/Mobile_Scanner/IRIScan_Pro_5/image-pdf.png';

  url = '';

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  get index() {
    return tenantModule.accommodations.findIndex((x: any) => x.id === this.id);
  }

  //   get images() {
  //     if (this.type === 'media') {
  //       return this.accommodation.media;
  //     }
  //     return this.accommodation?.AccommodationDocuments;
  //   }

  //   set images(event) {
  //     const copy = clone(tenantModule.accommodation);

  //     if (this.type === 'media') {
  //       this.accommodation.media = event;
  //     } else {
  //       this.accommodation.AccommodationDocuments = event;
  //     }

  //     this.$emit('update:accommodation', this.accommodation);

  //     if (copy) {
  //       if (this.type === 'media') {
  //         copy[this.index].media = event ?? [];
  //       } else {
  //         copy[this.index].AccommodationDocuments = event ?? [];
  //       }

  //       //   tenantModule.setAccommodation(copy);
  //     }
  //   }

  //   created() {
  //     if (this.uploads) {
  //       this.files = this.uploads;
  //     }
  //   }

  mounted() {
    this.url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/${this.id}/${this.type}/upload`;
  }

  public async onUploadError(_err: any) {
    const token = await this.$msal.acquireToken();

    Auth.login(token);
  }

  public success(event: any) {
    this.accommodationSync = event[0].data;
    this.$emitter.emit('upload', uploadNotify());
  }

  public async onDelete(event: any) {
    try {
      const res = await service.delete(`accommodation/${event.name()}/${event.id}`);

      this.accommodationSync = res.data;
      this.$emitter.emit('upload', deleteNotify());
    } catch (error: any) {
      this.$emitter.emit('error', errorNotify(error));
    }
  }
}
