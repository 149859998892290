
import { Component, PropSync, Vue } from 'vue-property-decorator';
import { AccommodationDto, IAddressDto } from '@/app/entities/tenant/accommodation.dto';

type Place = { geometry?: any; types?: any; name?: any; formatted_address?: any; longitude: number; latitude: number };
@Component
export default class GoogleMapInput extends Vue {
  @PropSync('syncedAccommodation') accommodationSync!: any;

  // eslint-disable-next-line camelcase
  jdata: { starting_point?: string; geo: { lat: ''; lng: '' } } = { starting_point: '', geo: { lat: '', lng: '' } };

  placeStart = {};

  latLng = '';

  // place: Place | null = null;

  get place(): Place | null {
    return this.accommodationSync.address.name ? this.accommodationSync.address : null;
  }

  gMapFunc(event: { lat: () => any; lng: () => any }) {
    this.jdata = { geo: { lat: event.lat(), lng: event.lng() } };
  }

  get centerMap() {
    if (!this.place) {
      return { lat: 0, lng: 0 };
    }
    return {
      lat: this.place.latitude,
      lng: this.place.longitude,
    };
  }

  get zoom() {
    return this.place ? 15 : 1;
  }

  get accommodationMarker(): Partial<IAddressDto> | null {
    const { lat, lng } = this.accommodationSync.address;
    if (!lat && !lng) {
      return null;
    }
    return this.accommodationSync.address;
  }

  get address(): any {
    return this.accommodationSync.address as Partial<IAddressDto>;
  }

  get geometry(): {
    lat: any;
    lng: any;
  } {
    if (this.address.geometry) {
      this.address.latitude = this.address.geometry.location.lat();
      this.address.longitude = this.address.geometry.location.lng();
    }

    if (!this.accommodationSync.address) {
      throw new Error();
    }
    return {
      lat: this.accommodationSync.address.latitude || -33.928984462979635,
      lng: this.accommodationSync.address.longitude || 28,
    };
  }

  getAddressData(addressData: any): void {
    this.accommodationSync.address = addressData;
  }

  // eslint-disable-next-line camelcase
  processStartChanged(placeStart: { geometry?: any; types?: any; name?: any; formatted_address?: any }): void {
    this.accommodationSync.address = placeStart;
    this.placeStart = placeStart;
    // this.place = placeStart;
    if (this.placeStart) {
      const lat = placeStart.geometry.location.lat();
      const lon = placeStart.geometry.location.lng();

      const starting = '';
      // eslint-disable-next-line no-unused-expressions
      //   !placeStart.types.includes('route')
      //     ? (starting = `${placeStart.name} ${placeStart.formatted_address}`)
      //     : (starting = placeStart.formatted_address);
      this.jdata.starting_point = starting;

      this.latLng = `${lat},${lon}`;
    }
  }
}
