import { render, staticRenderFns } from "./AccommodationDetails.vue?vue&type=template&id=612f780a&"
import script from "./AccommodationDetails.vue?vue&type=script&lang=ts&"
export * from "./AccommodationDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports