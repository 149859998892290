
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { mixins } from 'vue-class-component';
import Upload from '@/components/Upload.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import Tinymce from '@/components/Tinymce/index.vue';
import { Auth } from '@/store/modules/auth.module';
import * as _ from 'lodash';
import attachment from '@/components/attachment/attachment.vue';
import AccommodationSchema from '@/modules/accommodation/schema/accommodation.schema';
import { AccommodationDto, AccommodationDtoDocuments, IIndemnity } from '../../../app/entities/tenant/accommodation.dto';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({
  components: {
    Upload,

    Tinymce,

    attachment,
  },
})
export default class ExtraDocuments extends mixins(AccommodationSchema) {
  @Ref('form') readonly form: any;

  @Ref('documentForm') documentForm: any;

  @PropSync('active') activeSync!: boolean;

  @PropSync('currentStep') syncedCurrentStep!: number;

  @PropSync('accommodation') syncedAccommodation!: any;

  @PropSync('singleDocument') syncedSingleDocument!: any;

  @Prop({ default: () => null }) accommodationEdit: any;

  $refs!: any;

  baseUrl = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/upload/`;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  decreaseStep() {
    this.syncedCurrentStep -= 1;
  }

  isLoading = false;

  _ = _;

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  get uploadUrl(): string | undefined {
    return this.baseUrl;
  }

  validate(form: FormulateForm, args: string): boolean {
    return form?.formSubmitted() && args !== '';
  }

  documentSubmitHandler(val: any): any {
    const docObj = val;
    const url = val.file[0].url;
    docObj.url = url;
    const doc = new AccommodationDtoDocuments(docObj);
    this.syncedAccommodation.accommodationDocuments.push(doc);
    this.$formulate.reset('f-1');
    this.syncedSingleDocument = {
      attachEmail: false,
    };
  }

  deleteRow(e: any) {
    const index = this.syncedAccommodation.accommodationDocuments.indexOf(e);
    this.syncedAccommodation.accommodationDocuments.splice(index, 1);
  }

  // public success(event: any): void {
  //   this.syncedAccommodation.logo = event[0].data;
  // }
}
