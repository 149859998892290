
















// @ is an alias to /src

import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import Editor from '@tinymce/tinymce-vue';
import { mixins } from 'vue-class-component';
import Upload from '@/components/Upload.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Loading } from '@/modules/auth/store/auth.service';
import Tinymce from '@/components/Tinymce/index.vue';
import CibButton from '@/components/buttons/CibButton.vue';
import { Auth } from '@/store/modules/auth.module';
import Stepper from '@/app/components/stepper/Stepper.vue';
import * as _ from 'lodash';
import attachment from '@/components/attachment/attachment.vue';
import { isEmpty } from 'lodash';
import AccommodationSchema from '@/modules/accommodation/schema/accommodation.schema';
import { getHashMap } from '@/app/utils/array';
import { AccommodationDto, AccommodationDtoDocuments, IIndemnity } from '../../../app/entities/tenant/accommodation.dto';
import { appModule } from '../../../store/modules/app.module';

import AccommodationDetails from '@/modules/accommodation/components/AccommodationDetails.vue';
import CustomiseForm from '@/modules/accommodation/components/CustomiseForm.vue';
import HealthQuestionnaire from '@/modules/accommodation/components/HealthQuestionnaire.vue';
import Location from '@/modules/accommodation/components/Location.vue';
import IndemnitiesAndTerms from '@/modules/accommodation/components/IndemnitiesAndTerms.vue';
import ExtraDocuments from '@/modules/accommodation/components/ExtraDocuments.vue';
import { isNull } from 'util';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({
  components: {
    Upload,
    Editor,
    Tinymce,

    CibButton,
    Stepper,
    attachment,
    AccommodationDetails,
    CustomiseForm,
    HealthQuestionnaire,
    Location,
    IndemnitiesAndTerms,
    ExtraDocuments,
  },
  name: 'new-accommodation',
  layout: 'AppMain',
})
export default class Create extends mixins(AccommodationSchema) {
  @Ref('form') readonly form: any;

  @Ref('detailsForm') readonly detailsForm: FormulateForm;

  @Ref('healthDisclaimerForm') readonly healthDisclaimerForm: FormulateForm;

  @Ref('documentForm') documentForm: any;

  @PropSync('active') activeSync!: boolean;

  // @Prop({ default: () => null }) accommodationEdit: any;

  @PropSync('editingAccommodation') accommodationEdit: any;

  @PropSync('isEditing') isEditingSync: boolean;

  $refs!: any;

  baseUrl = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/upload/`;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  tinyModel = '';

  logoSub = 'Accommodation logo';

  vsAlert = true;

  hasLogoSubmit = false;

  logo = '';

  currentStep = 0;

  // isEditing = false;

  steps = ['Accomodation details', 'Customise check-in form', 'Health questionnaire', 'Location', 'Indemnities & terms', 'Extra documents'];

  singleDocument: any = {
    attachEmail: false,
  };

  isLoading = false;

  _ = _;

  accommodation: AccommodationDto = new AccommodationDto();

  isOn = false;

  activeDoc = 0;

  questionsRequired = true;

  // questions: Record<string, any>[] = [];

  selectedQuestions: number[] = [];

  deselectedCustomQuestions: string[] = [];

  accommodationIndemnity = [];

  directions = { url: '' } as any;

  editDirections: { url: ''; name: '' }[] | null = null;

  editedAccomodation: AccommodationDto;

  @Watch('currentStep')
  checkStep(newStep: number, oldStep: number) {
    /** i keep a copy of the filledin accom form on submit
    and use that to fill if they go back a step */
    if (newStep == 0 && newStep < oldStep) {
      this.$nextTick(() => {
        this.accommodation = this.editedAccomodation;
        /*         this.detailsSchema[0].children[0].value.url = '/path/to/document.pdf';
         */
      });
    }
  }

  hasQuestion(id: number) {
    return this.selectedQuestions.some((key) => key === id);
  }

  cancelCreation() {
    this.isEditingSync = false;
    // this.accommodation = {} as AccommodationDto;
    // this.accommodationEdit = {} as AccommodationDto;
    // console.log(this.accommodationEdit, 'accom edit');
    // console.log(this.accommodation, 'accom');
    // this.$refs.accommodationDetails.resetForm();
    this.$router.push('/organisation/accommodations');
  }

  created() {
    if (!isEmpty(this.accommodationEdit) && this.isEditingSync) {
      this.accommodation = this.accommodationEdit;
      this.accommodationIndemnity = this.accommodationEdit.indemnity;
      this.logo = this.accommodationEdit.logo;
      if (this.accommodationEdit.directions) {
        if (this.accommodationEdit.directions.url == '' && this.accommodationEdit.directions.name == '') {
          this.editDirections = null as any;
        } else {
          this.editDirections = [this.accommodationEdit.directions];
        }
      } else {
        this.editDirections = null as any;
      }
    } else {
      this.accommodation.address = {
        name: '',
        formatted_address: '',
        formatted_phone_number: '',
        url: '',
        international_phone_number: '',
      };
    }
    this.directions = this.accommodation.address;
    this.directions.directions = this.editDirections;
  }

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  get uploadUrl(): string | undefined {
    return this.baseUrl;
  }

  get isMember() {
    return !this.$ability.can('create', 'accommodation');
  }

  deleteAccommodation(id: any, i: any): void {
    this.$emit('clicked', id, i);
  }

  validate(form: FormulateForm, args: string): boolean {
    return form?.formSubmitted() && args !== '';
  }
  event(e: any) {
    console.log(e);
  }

  next(step: number) {
    if (step) console.log(step);
  }

  stepperSubmitHandler(stepperSubmit: boolean, index: number) {
    switch (this.currentStep) {
      case 0:
        this.$refs.accommodationDetails.submitController(stepperSubmit, index);
        break;
      case 2:
        this.$refs.healthQuestionnaire.validateNext(stepperSubmit, index);
        break;
      case 4:
        this.$refs.indemnitiesAndTerms.validateNext(stepperSubmit, index);
        break;
    }
  }

  async saveAndExit() {
    switch (this.currentStep) {
      case 0:
        this.$refs.accommodationDetails.submitController(null, _);
        break;
      case 2:
        this.$refs.healthQuestionnaire.validateNext(null, _);
        break;
      case 4:
        this.$refs.indemnitiesAndTerms.validateNext(null, _);
        break;
      default:
        this.complete();
    }
  }

  @Loading
  async complete(): Promise<void> {
    let payload = _.omit(this.accommodation, 'file') as AccommodationDto;
    payload.phone = getHashMap(this.accommodation.phone);
    payload.emergencyNumber = getHashMap(this.accommodation.emergencyNumber);
    payload.indemnity = this.accommodationIndemnity;
    payload.logo = this.logo[0];
    payload.rooms = parseInt(this.accommodation.rooms as any, 10);
    payload.latitude = this.accommodation.address.latitude as number;
    payload.longitude = this.accommodation.address.longitude as number;
    payload.questionsRequired = this.questionsRequired;
    payload.guestConfirmation.confirmationSchema = this.deselectedCustomQuestions;
    this.selectedQuestions.sort((a: number, b: number) => a - b);

    payload.healthQuestions = this.selectedQuestions.map((id) => {
      return {
        questionId: id,
      };
    });
    if (this.directions.directions?.files?.length) {
      payload.directions = {
        url: this.directions.directions.files[0]?.path?.url,
        name: this.directions.directions.files[0]?.path?.name,
      };
    } else if (this.directions.directions) {
      payload.directions = {
        url: this.directions.directions[0].url,
        name: this.directions.directions[0].name,
      };
    } else {
      payload.directions = {
        url: '',
        name: '',
      };
    }

    Object.keys(this.accommodation).find((v) => {
      if (v.includes('image_')) {
        payload.logo = this.accommodation[v].files[0].path.url;
        payload = _.omit(payload, v) as any;
      }
    });

    try {
      this.accommodation = await tenantModule.createAccommodation(payload as AccommodationDto);
      this.submit();
    } catch (error) {
      console.log(error);
    }
  }

  @Loading
  async submit(): Promise<void> {
    tenantModule.getAccommodations();
    if (!isEmpty(this.accommodationEdit)) {
      this.$swal('Updated', `${this.accommodationEdit.name} updated successfully`, 'info');
    } else {
      this.$swal('Created!', 'Your accommodation has been created. Start adding bookings to invite your guests', 'success');
    }
    this.activeSync = false;
    this.isEditingSync = false;
    this.$router.push('/organisation/accommodations');
  }

  public success(event: any): void {
    this.accommodation.logo = event[0].data;
  }

  getFormOptions(options: Record<string, any>[]) {
    /*     console.log(options);
     */ if (options) return _.omit(_.omit(options, 'optionGroupId'), 'optionGroupId');
    // let mappedOptions: any = {};

    // options.forEach((v) => {
    //   mappedOptions[v.id] = v.value;
    // });
    // console.log(mappedOptions);
    // return mappedOptions;
  }
}
