var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schema-group flex-wrap"},[_c('div',{staticClass:"w-full m-auto pt-5 pb-6"},[_c('label',{staticClass:"block text-sm font-medium text-gray-500 dark:text-white",attrs:{"for":"first_name"}},[_vm._v("Accommodation name or address")]),_c('gmap-autocomplete',{ref:"input",staticClass:"\n        w-full\n        p-3\n        text-xs\n        font-semibold\n        border-2 border-solid\n        leading-none\n        rounded\n        outline\n        border-gray-300\n        focus:ring-red-600\n        dark:bg-gray-400 dark:text-black\n      ",attrs:{"value":_vm.accommodationSync.address.formatted_address,"select-first-on-enter":true,"placeholder":"","classname":"form-control","options":{
        fields: [
          'geometry',
          'formatted_address',
          'address_components',
          'url',
          'name',
          'formatted_phone_number',
          'international_phone_number',
          'types' ],
      }},on:{"input":function($event){_vm.value = $event.target.value},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.preventDefault()},"place_changed":_vm.processStartChanged},scopedSlots:_vm._u([{key:"input",fn:function(slotProps){return [_c('input',{ref:"input",attrs:{"outlined":"","prepend-inner-icon":"place","placeholder":"Location Of Event"},on:{"listeners":slotProps.listeners,"attrs":slotProps.attrs}})]}}])})],1),_c('GmapMap',{staticClass:"m-auto",staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.geometry,"zoom":_vm.zoom,"map-type-id":"roadmap"}},[(_vm.place)?_c('gmap-marker',{attrs:{"position":_vm.geometry,"draggable":true,"place":_vm.accommodationMarker},on:{"dragend":function($event){return _vm.gMapFunc($event.latLng)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }